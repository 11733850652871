import React from "react"
import Layout from "../../components/layout/layout"
import SmallHero from "../../components/layout/smallHero"
import Parcours from "../../img/course/parcours.png"
import Profil from "../../img/course/profil.png"
import "../mystyles.scss"
import { Helmet } from "react-helmet"

export default function Home() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Les 5Km 2020</title>
      </Helmet>
      <SmallHero title="Les 5Km 2020" color="is-success" />
      <article className="section">
        <div className="container">
          <h1 className="title">Présentation de la course</h1>
          <p>
            La team kangourou organise, 2 à 3 fois par année, une course dans le
            grand Fribourg. Cette course permet à tous les participants de
            tester ses capacités sur une distance de 5km. Cette course est
            exclusivement réservée aux membres du club ! Vous attaquerez ce
            parcours par une première boucle de 2,2km qui comprend une belle
            première descente suivi de 50m de dénivelé positif afin de faire
            chauffer les cuisses. Une fois la boucle finie et un second passage
            sur la ligne de départ, le plus dur est déjà fait ! En effet, une
            longue ligne droite à plat sur 2km vous attend pour vous emmener
            finir votre course sur un... pont ! Et pour vous aider sur la fin du
            parcours, nous avons demandé aux architectes de faire ce pont en
            pente ! Idéal pour que tu puisses battre ton record sur cette
            distance.
          </p>
          <div className="has-text-centered">
            Lieu de rendez vous : Route du Bleuet 1762 Givisiez <br />
            Heure de rendez vous : 18h30 Départ de la course : 19h10
          </div>
          <p>Un ravitaillement copieux vous sera proposé à l'arrivée !</p>
          <br />
          <div className="columns">
            <div className="column is-half">
              <h2 className="subtitle has-text-centered">Le parcours</h2>
              <figure className="image">
                <img src={Parcours} alt="parcours 5km" />
              </figure>
            </div>
            <div className="column is-half has-text-centered">
              <h2 className="subtitle">Le dénivelé</h2>
              <figure className="image">
                <img
                  src={Profil}
                  alt="profil du parcours"
                  style={{
                    maxWidth: "75%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
              </figure>
            </div>
          </div>
        </div>
      </article>
    </Layout>
  )
}
